import type { CustomElement } from '@integrabeauty/custom-elements';
import html from './index.html';
import styles from './index.scss';

/**
 * Renders product variant
 *
 * @todo inline properties
 * @todo document dataset properties
 */
class ProductVariantSelectorModal extends HTMLElement implements CustomElement {
  public static get observedAttributes() {
    return [
      'data-show'
    ];
  }

  readonly dataset!: {
    currencyCode: string;
    currencySymbol: string;

    /**
     * Email of currently signed in customer.
     */
    customerEmail: string;

    /**
     * Show or hide variant selector modal, boolean value.
     */
    show: string;

    /**
     * This is passed through to internal element that requires knowledge of the liquid template
     * name value.
     *
     * @todo switch to using a feature flag once underlying element no longer requires this value
     */
    templateName: string;
  };

  public shadowRoot!: ShadowRoot;

  private product: Partial<Product>;
  private selectedVariantId: number;
  private country: string;
  private selectedOptions: Record<string, string> = {};

  private onSelectOptionRequestBound = this.onSelectOptionRequest.bind(this);
  private handleClosingModalBound = this.handleClosingModal.bind(this);
  private onProductVariantOptionChangedBound = this.onProductVariantOptionChanged.bind(this);

  constructor() {
    super();

    this.attachShadow({ mode: 'open' });
    this.shadowRoot.innerHTML = `<style>${styles}</style>${html}`;
  }

  public connectedCallback() {
    addEventListener('select-option-request', this.onSelectOptionRequestBound);
    addEventListener('shroud-clicked', this.handleClosingModalBound);
    addEventListener('cart-transaction-completed', this.handleClosingModalBound);
    addEventListener('notify-me-clicked', this.handleClosingModalBound);
    addEventListener('product-variant-option-changed',
      this.onProductVariantOptionChangedBound);

    const closeButton = this.shadowRoot.querySelector('.close-selector-modal');
    closeButton?.addEventListener('click', this.handleClosingModalBound);
  }

  public disconnectedCallback() {
    removeEventListener('select-option-request', this.onSelectOptionRequestBound);
    removeEventListener('shroud-clicked', this.handleClosingModalBound);
    removeEventListener('cart-transaction-completed', this.handleClosingModalBound);
    removeEventListener('notify-me-clicked', this.handleClosingModalBound);
    removeEventListener('product-variant-option-changed',
      this.onProductVariantOptionChangedBound);

    const closeButton = this.shadowRoot.querySelector('.close-selector-modal');
    closeButton?.removeEventListener('click', this.handleClosingModalBound);
  }

  public attributeChangedCallback(name: string, oldValue: string, newValue: string) {
    if (name === 'data-show' && oldValue !== newValue) {
      if (newValue === 'true') {
        type Detail = WindowEventMap['modal-show-requested']['detail'];
        const modalShowEvent = new CustomEvent<Detail>('modal-show-requested',
          { detail: { element: this } }
        );
        dispatchEvent(modalShowEvent);
      }

      if (newValue === 'false' && oldValue === 'true') {
        type ModalDetail = WindowEventMap['modal-close-requested']['detail'];
        const modalCloseEvent = new CustomEvent<ModalDetail>('modal-close-requested',
          { detail: { element: this } }
        );
        dispatchEvent(modalCloseEvent);
      }
    }
  }

  private handleClosingModal(_event: Event) {
    if (this.dataset.show === 'true') {
      this.dataset.show = 'false';
    }
  }

  private onProductVariantOptionChanged(event: WindowEventMap['product-variant-option-changed']) {
    // if we are on the product detail page of the product variant being changed we short circuit.
    if (location.pathname === event.detail.url) {
      return;
    }

    for (const option of event.detail.option_groups || []) {
      this.selectedOptions[option.name] = option.selected_option;
    }

    this.selectedVariantId = event.detail.selected_variant_id;
    this.renderSelectedVariant();
    this.renderBackorderMessage();
    this.setOOSForSecondOptionGroup();
  }

  private onSelectOptionRequest(event: WindowEventMap['select-option-request']) {
    this.product = event.detail?.product;
    this.selectedVariantId = this.product.selected_variant_id;
    this.country = event.detail?.country;
    this.selectedOptions = {};

    for (const option of this.product.option_groups) {
      this.selectedOptions[option.name] = option.selected_option;
    }

    this.render();
    this.setOOSForSecondOptionGroup();
    this.dataset.show = 'true';

    this.shadowRoot.querySelector<HTMLElement>('.close-selector-modal').focus();
  }

  private render() {
    if (!this.product?.title) {
      return;
    }

    this.renderTitle();
    this.renderVariantOptions();
    this.renderBackorderMessage();
    this.renderSelectedVariant();
    this.renderPricing();
    this.renderMessage();
    this.renderPrimaryAction();
    this.renderProductLink();
  }

  renderTitle() {
    const titleEl = this.shadowRoot.querySelector<HTMLElement>('h2');
    titleEl.innerText = this.product.title;
  }

  renderPricing() {
    const pricingEl = this.shadowRoot.querySelector('.pricing');
    if (pricingEl) {
      pricingEl.innerHTML = '';
    }

    const priceElement = document.createElement('product-price');
    priceElement.dataset.currencyCode = this.dataset.currencyCode;
    priceElement.dataset.currencySymbol = this.dataset.currencySymbol;

    let selectedVariant = this.product.variants.find(variant =>
      variant.id === this.product.selected_variant_id);

    if (!selectedVariant) {
      selectedVariant = this.product.variants[0];
    }

    if (!selectedVariant) {
      return;
    }

    const productEl = document.createElement('product-element');
    productEl.setAttribute('slot', 'product-price-data');
    productEl.dataset.id = `${this.product.id}`;
    productEl.dataset.type = this.product.type;
    productEl.dataset.selectedVariantId = `${selectedVariant.id}`;

    for (const variant of this.product.variants) {
      const variantEl = document.createElement('variant-element');
      variantEl.dataset.id = `${variant.id}`;
      variantEl.dataset.price = `${variant.price}`;
      variantEl.dataset.compareAtPrice = `${variant.compare_at_price || 0}`;

      productEl.appendChild(variantEl);
    }

    priceElement.appendChild(productEl);

    pricingEl.appendChild(priceElement);
  }

  private renderBackorderMessage() {
    if (!this.product) {
      return;
    }

    const selectedVariant = this.product.variants.find(variant =>
      variant.id === this.selectedVariantId);

    const backorderMessage = this.shadowRoot.querySelector('.backorder');
    if (selectedVariant?.backorder) {
      backorderMessage.textContent = selectedVariant.backorder;
    } else {
      backorderMessage.textContent = '';
    }
  }

  private renderMessage() {
    const message = this.shadowRoot.querySelector<HTMLElement>('.message');
    message.innerHTML = '';
    message.classList.add('none');

    // We apply the additional condition of discount percentage not equaling 0 to account for
    // offer-1 overrides using just the final sale price to drive discount messaging overrides.

    if (!this.product.metafields_discount_code || (!this.product.metafields_discount_percentage &&
      this.product.metafields_discount_percentage !== 0)) {
      return;
    }

    const selectedVariant = this.product.variants.find(variant =>
      variant.id === this.product.selected_variant_id);

    // We are not guaranteed to find a selected variant due to misconfiguration. In this case we
    // do not render the discount message element.

    if (selectedVariant) {
      const discountMessageElement = document.createElement('product-discount-message');
      discountMessageElement.dataset.price = `${selectedVariant.price}`;
      discountMessageElement.dataset.currencyCode = this.dataset.currencyCode;
      discountMessageElement.dataset.currencySymbol = this.dataset.currencySymbol;
      discountMessageElement.dataset.compareAtPrice = `${selectedVariant.compare_at_price}`;
      discountMessageElement.dataset.discountCode = this.product.metafields_discount_code;
      discountMessageElement.dataset.discountPercentage =
        `${this.product.metafields_discount_percentage}`;

      if (this.product.metafields_discount_override) {
        discountMessageElement.dataset.messageOverride = this.product.metafields_discount_override;
      }

      message.append(discountMessageElement);
      message.classList.remove('none');
    } else {
      console.debug('Could not find selected variant when rendering discount text');
    }
  }

  renderVariantOptions() {
    const variantOptionsContainer = this.shadowRoot.querySelector('.variant-options');
    variantOptionsContainer.innerHTML = '';

    const variantOptionsEl = document.createElement('variant-options');
    variantOptionsEl.dataset.productId = this.product.id.toString();
    variantOptionsEl.dataset.productTitle = this.product.title;
    variantOptionsEl.dataset.productType = this.product.type;
    variantOptionsEl.dataset.productUrl = this.product.url;
    variantOptionsEl.dataset.selectedVariant = this.selectedVariantId.toString();
    variantOptionsEl.dataset.discountCode = this.product.metafields_discount_code;

    const variantOptionsSlotEl = document.createElement('div');
    variantOptionsSlotEl.slot = 'variant-option-groups';

    for (const optionGroup of this.product.option_groups) {
      const optionGroupEl = document.createElement('variant-option-group');
      optionGroupEl.dataset.productId = this.product.id.toString();
      optionGroupEl.dataset.title = optionGroup.name;
      optionGroupEl.dataset.selected = optionGroup.selected_option;
      optionGroupEl.dataset.position = optionGroup.position.toString();

      this.selectedOptions[optionGroup.name] = optionGroup.selected_option;

      const variantOptionsGroupSlot = document.createElement('div');
      variantOptionsGroupSlot.slot = 'variant-options';

      let count = 1;
      for (const option of optionGroup.options) {
        const optionName = toCSSClassName(optionGroup.name.toLowerCase());
        const optionId = `${this.product.id}${optionName}${count}`;
        const optionEl = document.createElement('div');
        optionEl.dataset.value = option.value;

        const inputEl = document.createElement('input');
        inputEl.type = 'radio';
        inputEl.name = `${this.product.id}${optionName}`;
        inputEl.id = optionId;
        inputEl.value = option.value;

        if (optionGroup.selected_option === option.value) {
          inputEl.checked = true;
        }

        const labelEl = document.createElement('label');
        labelEl.classList.add(optionName);

        // If the option group is of type color we must add the value of the current color as a
        // class to the label to ensure the color properly represented. The hex codes used depend on
        // the color value for the options to be added as a class. Color is the only option group
        // with this requirement.
        if (optionGroup.name === 'Color') {
          try {
            labelEl.classList.add(toCSSClassName(option.value.toLowerCase()));
          } catch (error) {
            console.warn('Failed to add variant color option class', option.value);
          }
        }

        if (isOutOfStock(option)) {
          labelEl.classList.add('out-of-stock');
        }

        labelEl.htmlFor = optionId;
        labelEl.textContent = option.value;

        optionEl.appendChild(inputEl);
        optionEl.appendChild(labelEl);

        variantOptionsGroupSlot.appendChild(optionEl);

        count++;
      }

      optionGroupEl.appendChild(variantOptionsGroupSlot);
      variantOptionsSlotEl.appendChild(optionGroupEl);
    }

    variantOptionsEl.appendChild(variantOptionsSlotEl);

    for (const variant of this.product.variants) {
      const div = document.createElement('variant-element');
      div.dataset.available = variant.available.toString();
      div.dataset.compareAtPrice = variant.compare_at_price?.toString();
      div.dataset.price = variant.price.toString();
      div.dataset.sku = variant.sku;
      div.dataset.title = variant.title;
      div.dataset.id = variant.id.toString();
      div.dataset.option1 = variant.option1;
      div.dataset.option2 = variant.option2;
      div.dataset.option3 = variant.option3;

      variantOptionsEl.appendChild(div);
    }

    variantOptionsContainer.appendChild(variantOptionsEl);
  }

  private renderSelectedVariant() {
    const selectedValues = [];
    for (const selected in this.selectedOptions) {
      const className = toCSSClassName(this.selectedOptions[selected]);
      if (selected === 'Color') {
        selectedValues.push(
          `<span class="color-swatch ${className.toLowerCase()}"></span>` +
          `<span class="option-text">${className}</span>`);
      } else {
        selectedValues.push(`<span class="option-text">${className}</span>`);
      }
    }

    const selectedEl = this.shadowRoot.querySelector('.selected');
    selectedEl.innerHTML = selectedValues.join('<span class="separator">|</span>');
  }

  private renderPrimaryAction() {
    let selectedVariant = this.product.variants.find(variant =>
      variant.id === this.product.selected_variant_id);

    if (!selectedVariant) {
      selectedVariant = this.product.variants[0];
    }

    if (!selectedVariant) {
      return;
    }

    const addToCart = document.createElement('add-to-cart');
    addToCart.dataset.templateName = this.dataset.templateName;
    addToCart.dataset.country = this.country;
    addToCart.dataset.productListingDisplay = 'true';
    addToCart.dataset.hideBackorderMessage = 'true';
    addToCart.dataset.backorder = selectedVariant.backorder;
    addToCart.dataset.discountCode = this.product.metafields_discount_code;
    if (this.dataset.customerEmail) {
      addToCart.dataset.customerEmail = this.dataset.customerEmail;
    }

    const product = document.createElement('product-element');
    product.setAttribute('slot', 'product');
    product.dataset.id = `${this.product.id}`;
    product.dataset.available = `${this.product.available}`;
    product.dataset.url = this.product.url;
    product.dataset.title = this.product.title;
    product.dataset.type = this.product.type;
    product.dataset.selectedVariantId = `${selectedVariant.id}`;
    product.dataset.tags = this.product.tags || '';

    for (const variant of this.product.variants) {
      const variantElement = document.createElement('variant-element');
      variantElement.dataset.id = `${variant.id}`;
      variantElement.dataset.available = `${variant.available}`;
      variantElement.dataset.sku = variant.sku;
      variantElement.dataset.title = variant.title;

      if (variant.backorder) {
        variantElement.dataset.backorder = variant.backorder;
        if (variant.hide_backorder_in_collection) {
          variantElement.dataset.hideBackorderInCollection = variant.hide_backorder_in_collection;
        }
      }

      // It is possible that this.product.tags is undefined
      const tags = this.product.tags || '';

      if (tags.includes('Contains Alcohol')) {
        const customAttribute = document.createElement('custom-attribute');
        customAttribute.dataset.key = '_contains_alcohol';
        customAttribute.dataset.value = 'true';
        variantElement.appendChild(customAttribute);
      }

      if (tags.includes('compatibility-soleil')) {
        const customAttribute = document.createElement('custom-attribute');
        customAttribute.dataset.key = '_compatibility';
        customAttribute.dataset.value = 'soleil';
        variantElement.appendChild(customAttribute);
      }

      if (tags.includes('compatibility-max-high-volume')) {
        const customAttribute = document.createElement('custom-attribute');
        customAttribute.dataset.key = '_compatibility';
        customAttribute.dataset.value = 'max-high-volume';
        variantElement.appendChild(customAttribute);
      }

      if (tags.includes('final-sale')) {
        const customAttribute = document.createElement('custom-attribute');
        customAttribute.dataset.key = '_final_sale';
        customAttribute.dataset.value = 'true';
        variantElement.appendChild(customAttribute);
      }

      product.appendChild(variantElement);
    }

    addToCart.appendChild(product);

    addToCart.dataset.full = 'true';

    const primaryActionEl = this.shadowRoot.querySelector('.primary-action');
    primaryActionEl.innerHTML = '';
    primaryActionEl.appendChild(addToCart);
  }

  renderProductLink() {
    const productLinkEl = this.shadowRoot.querySelector('.product-link');
    productLinkEl.innerHTML = '';
    const anchor = document.createElement('a');
    anchor.setAttribute('href', this.product.url);
    anchor.innerText = 'View Details';
    productLinkEl.appendChild(anchor);
  }

  private setOOSForSecondOptionGroup() {
    // When the product has 2 option groups, there can be a case when for the selected option1
    // variant for some option2 is not available. We need to mark them as OOS.
    // E.g. there are 2 option groups: Color (with options "Blush" and "Black") and Size (with
    // options "25mm" and "75mm"). For the option "Blush" there is no available variant. So when the
    // user select "Blush" all Size options will be marked as OOS.

    if (!this.product || this.product.option_groups.length < 2) {
      return;
    }

    const mainOptionName = this.product.option_groups[0].name;
    const mainOptionValue = this.selectedOptions[mainOptionName];

    const variants = this.product.variants
      .filter(variant => variant.option1 === mainOptionValue);
    for (const variant of variants) {
      const option2 = variant.option2;
      const label = this.shadowRoot.querySelector(
        `variant-option-group[data-position="2"] [data-value="${option2}"] label`);

      if (!label) {
        continue;
      }

      if (variant.available) {
        label.classList.remove('out-of-stock');
      } else {
        label.classList.add('out-of-stock');
      }
    }
  }
}

function isOutOfStock(option: ProductVariantOption) {
  return option.variants.every(variant => !variant.available);
}

function toCSSClassName(value: string) {
  return value.replace(/\W/g, '-');
}

interface ProductVariantOption {
  value: string;
  variants: Partial<{
    /**
     * Whether the variant has inventory in stock and is available for sale.
     */
    available: boolean;

    backorder_message: string;

    compare_at_price: number;

    customAttributes: {
      /**
       * Key or name of the attribute.
       */
      key: string;

      /**
       * Value of the attribute.
       */
      value: string;
    }[];

    hideBackorderInCollection: string;

    /**
     * Variant id
     */
    id: number;

    price: number;

    sku: string;

    title: string;
  }>[];
}

interface Product {
  /**
   * Product inventory availability
   */
  available: boolean;

  /**
   * Product award image
   */
  award_badge_image: string;

  /**
   * Product award tag
   */
  award_tag: string;

  /**
   * Product badge type
   */
  badge_type: '' | 'best-seller' | 'favorite' | 'ideal-for-you' | 'new' | 'travel-friendly';

  /**
   * Product main image
   */
  hover_image: string;

  /**
   * Shopify product id
   */
  id: number;

  /**
   * Product main image loading method
   */
  lazyload: boolean;

  /**
   * Product main image
   */
  main_image: string;

  /**
   * Product metafields discount code (used to promote public discount codes)
   */
  metafields_discount_code: string;

  /**
   * Product metafields discount override to use one-off messages instead of generated text
   */
  metafields_discount_override: string;

  /**
   * Product metafields discount percentage (used to render public discount code prices)
   */
  metafields_discount_percentage: number;

  /**
   * Product metafields subtitle
   */
  metafields_subtitle: string;

  /**
   * Product metafields title
   */
  metafields_title: string;

  option_groups: {
    name: string;
    options: {
      value: string;
      variants: Partial<{
        /**
         * Whether the variant has inventory in stock and is available for sale.
         */
        available: boolean;

        backorder_message: string;

        compare_at_price: number;

        customAttributes: {
          /**
           * Key or name of the attribute.
           */
          key: string;

          /**
           * Value of the attribute.
           */
          value: string;
        }[];

        hideBackorderInCollection: string;

        /**
         * Variant id
         */
        id: number;

        price: number;

        sku: string;

        title: string;
      }>[];
    }[];
    position?: number;
    selected_option: string;
  }[];

  /**
   * Product yotpo reviews average value
   */
  reviews_average: number;

  /**
   * Product yotpo reviews count
   */
  reviews_count: number;

  /**
   * Selected variant id
   */
  selected_variant_id: number;

  /**
   * Product sku
   */
  sku: string;

  /**
   * Product tags
   */
  tags: string;

  /**
   * Product title
   */
  title: string;

  /**
   * Product type
   *
   * @example "hair-care"
   */
  type: string;

  /**
   * Shopify product url
   */
  url: string;

  /**
   * Represents a product with multiple variants that is part of a value set. This value is used to
   * inform any subscribers consuming the product-variant-option-changed event that a product with
   * an ID not matching the parent product still requires it's DOM data indicating the actively
   * selected product.
   *
   * @todo this should be removed
   *
   * @deprecated
   */
  value_set_parent_id: number;

  /**
   * A list of the product's variants with some information about variant
   */
  variants: {
    /**
     * Whether the variant is in stock
     */
    available: boolean;

    /**
     * Whether the variant is on backorder
     */
    backorder: string;

    /**
     * The MSRP, or original sales price before discounts, per unit
     */
    compare_at_price: number;

    /**
     * The currency code associated with the price
     */
    currency_code: string;

    /**
     * @todo clarify what this means
     */
    hide_backorder_in_collection: string;

    /**
     * Variant id
     *
     * @example 12345568943
     */
    id: number;

    /**
     * The value of the variant for the first product option. If there's no first product option,
     * then nil is returned.
     */
    option1: string;

    /**
     * The value of the variant for the second product option. If there's no first product option,
     * then nil is returned.
     */
    option2: string;

    /**
     * The value of the variant for the third product option. If there's no first product option,
     * then nil is returned.
     */
    option3: string;

    /**
     * The price of the variant
     *
     * @todo clarify, is this in cents?
     */
    price: number;

    /**
     * Whether this variant is the currently selected variant out of all the variants for the
     * product
     */
    selected: boolean;

    /**
     * The Stock Keeping Unit associated with the variant. This is another kind of product id.
     */
    sku: string;

    /**
     * The title of the variant.
     */
    title: string;
  }[];
}

declare global {
  interface HTMLElementTagNameMap {
    'product-variant-selector-modal': ProductVariantSelectorModal;
  }
}

if (!customElements.get('product-variant-selector-modal')) {
  customElements.define('product-variant-selector-modal', ProductVariantSelectorModal);
}
